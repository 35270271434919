
import { mapActions, mapGetters, mapState } from 'vuex';
import { Options, Vue } from 'vue-class-component';
import { View } from '@/interfaces';
import DailyCo from '@/daily';
import { User } from '@/consts';
import { RecState } from '@/enums';
import ExamIntro from './ExamIntro.vue';
import ExamQuestion from './ExamQuestion.vue';
import ExaminerVideo from './ExaminerVideo.vue';
import ExamMark from './ExamMark.vue';

@Options({
  components: {
    ExaminerVideo,
    ExamIntro,
    ExamQuestion,
    ExamMark,
  },
  sockets: {
    confirmStartRecording(isStartRecordingConfirmed: boolean) {
      if (isStartRecordingConfirmed) {
        this.next();
        this.$socket.emit('startRecording');
      }
    },
    confirmStopRecording(isStopRecordingConfirmed: boolean) {
      if (isStopRecordingConfirmed) {
        this.next();
        this.$socket.emit('stopRecording');
      }
    },
    examNextStep() {
      const NEXT_STEP_REC_STATE = this.$store.getters['exam/nextStepRecState'];

      switch (true) {
        case this.view.recState === RecState.START_RECORDING && NEXT_STEP_REC_STATE === RecState.RECORDING: {
          this.$socket.emit('confirmStartRecording');
          return;
        }

        case this.view.recState === RecState.RECORDING && NEXT_STEP_REC_STATE === RecState.STOP_RECORDING: {
          this.$socket.emit('confirmStopRecording');
          return;
        }

        case this.view.recState === RecState.RECORDING: {
          this.next();
          break;
        }

        default:
          break;
      }
    },
    examPreviousStep() {
      const PREVIOUS_STEP_REC_STATE = this.$store.getters['exam/previousStepRecState'];

      switch (true) {
        case this.view.recState === RecState.RECORDING && PREVIOUS_STEP_REC_STATE === RecState.START_RECORDING: {
          return;
        }

        case this.view.recState === RecState.STOP_RECORDING: {
          return;
        }

        case this.view.recState === RecState.RECORDING: {
          this.prev();
          break;
        }

        default:
          break;
      }
    },
    examReplay() {
      this.replay();
    },
    sessionUpdated(session: any) {
      this.updateSession(session);
      this.joinDailyCall();
    },
  },

  data() {
    return {
      isImageError: false,
      isJoined: false,
      networkInterval: null,
      isScreenSharing: false,
    };
  },

  computed: mapState({
    session: (state: any) => state.session.data,
    examiner: (state: any) => state.session.examiner,
    modules: (state: any) => state.exam.modules,
    examError: (state: any) => state.exam.error,
  }),
  watch: {
    networkInterval: {
      handler() {
        this.sendExaminerNetworkQuality();
      },
    },
  },
  mounted() {
    this.getExamQuestions();
  },
  methods: {
    ...mapActions(['next', 'prev', 'replay', 'updateSession', 'updateExaminer', 'getExamQuestions']),
    ...mapGetters('exam', ['modules', 'nextStepRecState', 'previousStepRecState']),
    async sendExaminerNetworkQuality() {
      if (!this.networkInterval && this.isJoined) {
        this.networkInterval = setInterval(async () => {
          try {
            const res = await DailyCo.getDaily().getNetworkStats();
            this.$socket.emit(
              'connectionQuality',
              { user: User.EXAM, threshold: res?.threshold || null, quality: res?.quality || 0 },
            );
            clearInterval(this.networkInterval);
            this.networkInterval = null;
          } catch (error) {
            clearInterval(this.networkInterval);
            this.networkInterval = null;
          }
        }, 2000);
      }
    },
    joinDailyCall() {
      if (this.session.daily.clientToken && this.session.daily.roomUrl && !DailyCo.getIsDailyCallCreated()) {
        DailyCo.createDailyCall();

        DailyCo.joinDailyCall({
          clientToken: this.session.daily.clientToken,
          roomUrl: this.session.daily.roomUrl,
          userName: 'exam-app',
        });

        DailyCo.getDaily()
          .on('joined-meeting', () => this.updateParticipants('joined-meeting'))
          .on('participant-joined', this.updateParticipants)
          .on('participant-updated', this.updateParticipants)
          .on('participant-left', this.updateParticipants);
      }
    },
    updateParticipants(e: string) {
      if (!DailyCo.getIsDailyCallCreated()) return;

      if (e === 'joined-meeting') {
        this.isJoined = true;
        this.sendExaminerNetworkQuality();
        this.$socket.emit('isUserConnected', { name: User.EXAM, connected: true });
      }

      if (e === 'left-meeting') {
        this.isJoined = false;
      }

      this.participants = Object.values(DailyCo.getDaily().participants());

      this.isScreenSharing = DailyCo.getDaily().participants().local.screen;

      if (!DailyCo.getDaily().participants().local.screen) {
        DailyCo.getDaily().startScreenShare();
      }

      this.examiner = Object.values(this.participants).reduce((participantsAcc, participant: any) => {
        if (participant.user_name === 'examiner') {
          participantsAcc = {
            userId: participant.user_id,
            audio: participant.audio,
            video: participant.video,
            videoTrack: participant.tracks.video,
          };
        }

        return participantsAcc;
      }, {});

      this.updateExaminer(
        Object.values(this.participants).reduce((participantsAcc, participant: any) => {
          if (participant.user_name === 'examiner') {
            participantsAcc = {
              userId: participant.user_id,
              audio: participant.audio,
              video: participant.video,
              videoTrack: participant.tracks.video,
            };
          }

          return participantsAcc;
        }, {}),
      );

      this.loading = false;
    },
  },
})
export default class App extends Vue {
  get brand(): string {
    return this.$store.state.brand;
  }

  get mark(): string {
    return this.$store.state.exam.mark;
  }

  get examId(): string {
    return this.$store.state.exam.id || '';
  }

  get loading(): boolean {
    return this.$store.state.loading;
  }

  get showMark(): boolean {
    return this.view.image === '';
  }

  get originUrl(): string {
    return this.$store.state.originUrl;
  }

  get view(): View {
    return this.$store.getters['exam/currentView'];
  }
}
